<template>
  <div class="l_footer">
    <footer class="footer">
      <div class="footer_inner">
        <div class="footer_inner_top">
          <div class="l_sitemap">
            <ul class="sitemap">
              <li v-if="!isNextGiga">
                <a
                  :href="config.mottosokkaUrl + config.termOfServiceChildrenUrl"
                  target="_blank"
                  class="sitemap_link"
                  >お約束</a
                >
              </li>
              <li>
                <a
                  v-if="isNextGiga"
                  :href="config.mottosokkaUrl + '/giga-school-group-terms'"
                  target="_blank"
                  class="sitemap_link"
                  >団体利用規約</a
                >
                <a
                  v-else
                  :href="config.mottosokkaUrl + config.termOfServiceUrl"
                  target="_blank"
                  class="sitemap_link"
                  >団体利用規約</a
                >
              </li>
              <li>
                <a
                  :href="config.websiteAgreementUrl"
                  target="_blank"
                  class="sitemap_link"
                  >ウェブサイト利用規約</a
                >
              </li>
              <li>
                <a
                  :href="config.privacyPolicyUrl"
                  target="_blank"
                  class="sitemap_link"
                  >プライバシーポリシー</a
                >
              </li>
              <li v-show="display.smAndDown">
                <v-row class="mr-0">
                  <v-col class="foot_space" v-show="isUserTypeAdmin">
                    <router-link
                      :to="{
                        name: 'ExternalLink',
                        params: {
                          href: config.mottosokkaUrl + clientRegistrationUrl,
                        },
                      }"
                      class="sitemap_link"
                      >利用申込み
                    </router-link>
                  </v-col>
                  <v-col class="foot_space" v-if="hasAuthAdmin">
                    <router-link
                      :to="{
                        name: 'ExternalLink',
                        params: { href: config.adminUrl },
                      }"
                      class="sitemap_link"
                      >管理画面へ</router-link
                    >
                  </v-col>
                  <v-col class="foot_space" v-if="hasAuthAdmin">
                    <a
                      :href="config.mottoSokkaSupport"
                      target="_blank"
                      class="sitemap_link"
                      >サポートページ</a
                    >
                  </v-col>
                </v-row>
              </li>
            </ul>
            <ul class="sitemap sitemap__right" v-show="display.mdAndUp">
              <li>
                <v-row class="mr-0">
                  <v-col class="foot_space" v-show="isUserTypeAdmin">
                    <router-link
                      :to="{
                        name: 'ExternalLink',
                        params: {
                          href: config.mottosokkaUrl + clientRegistrationUrl,
                        },
                      }"
                      class="sitemap_link"
                      >利用申込み
                    </router-link>
                  </v-col>
                  <v-col class="foot_space" v-if="hasAuthAdmin">
                    <router-link
                      :to="{
                        name: 'ExternalLink',
                        params: { href: config.adminUrl },
                      }"
                      class="sitemap_link"
                      >管理画面へ</router-link
                    >
                  </v-col>
                  <v-col class="pr-0 foot_space" v-if="hasAuthAdmin">
                    <a
                      v-if="isNextGiga"
                      :href="config.nextGigaSupportTop"
                      target="_blank"
                      class="sitemap_link"
                      >サポートページ</a
                    >
                    <a
                      v-else
                      :href="config.mottoSokkaSupport"
                      target="_blank"
                      class="sitemap_link"
                      >サポートページ</a
                    >
                  </v-col>
                </v-row>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_inner_bottom">
          <div class="l_footinfo_left">
            <div class="footinfo_pic_poplar">
              <div class="rabo_logo">
                <v-img
                  src="@/assets/design/icon/rabo_logo.jpg"
                  alt="ポプラ社ロゴ"
                  contain
                />
              </div>
            </div>
            <div class="footinfo_txt">
              Copyright &copy; POPLAR Publishing Co.,Ltd All Rights Reserved.
              <br />当サイトに掲載の、写真、動画などの無断転載、加工など一切禁止しております。
            </div>
          </div>
          <div class="l_footinfo_right">
            <div class="abj_logo ml-3">
              <div class="footinfo_pic">
                <a
                  :href="config.mottosokkaUrl + config.aboutAbjUrl"
                  target="_blank"
                  class="footinfo_pic"
                >
                  <v-img
                    src="@/assets/design/icon/ABJ_crop_black.png"
                    alt="ABJマーク"
                    contain
                  />
                </a>
              </div>
            </div>
            <div class="jasrac_logo">
              <div class="footinfo_pic_Jasrac">
                <v-img
                  src="@/assets/design/icon/jasrac_footer_img.jpg"
                  alt="Jasrac_img"
                  contain
                />
              </div>
              <div class="footinfo_txt jasrac_txt ml-3">
                JASRAC許諾<br />第901675000<br />7Y45129号
              </div>
            </div>
            <div class="jasrac_logo">
              <div class="footinfo_pic_Jasrac">
                <v-img src="@/assets/design/icon/nex_tone_footer.png" alt="Jasrac_img" contain />
              </div>
              <div class="footinfo_txt jasrac_txt ml-3">許諾番号<br />ID000009225<br /></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed, ref, Ref } from 'vue'
import { injectionKeys } from '@/constants/injectionKeys'
import { useRoute } from 'vue-router'
import AuthHelper from '@/helpers/AuthHelper'
import { useDisplay } from 'vuetify'
import config from '@/config/config'
import { errorPageNames } from '@/router'

const display = ref(useDisplay())
const isNextGiga = inject<Ref<boolean>>(injectionKeys.isNextGiga)!

const clientRegistrationUrl = '/client_registration'

const route = useRoute()

// 404,500,503エラー判定
const isErrorPage = computed(() => {
  return typeof route.name === 'string' && errorPageNames.includes(route.name)
})

/**
 * 管理画面への権限を判定する
 * ユーザ種別が「教育委員会管理者」、「学校管理者」、「先生」、「アドミニストレータ」のいずれか、かつログイン後かつエラーページでない場合のみ表示
 * @returns {boolean} true:権限あり、false:権限なし
 */
const hasAuthAdmin = computed(() => {
  return AuthHelper.isPermittedUser() && !isErrorPage.value
})

/**
 * 管理者権限有無を判定する
 * @returns {boolean} true:管理者権限かつエラーページでない、false:管理者権限以外またはエラーページ
 */
const isUserTypeAdmin = computed(() => {
  return AuthHelper.isAdmin() && !isErrorPage.value
})
</script>
<style lang="scss" scoped>
@import 'Footer';
</style>
