<template>
  <div class="topPage">
    <section class="topPage__head">
      <div
        v-if="isUserId"
        class="ReadBookStatus"
        :class="
          showStatus.ReadBookStatus ? 'anime-popIn is-active' : 'anime-popIn'
        "
      >
        <ReadBookStatus
          :firstPage="{
            backgroundImage: require('@/assets/image/top/book_pages.svg'),
            title:
              '<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby >んだページ<ruby ><rb>数</rb><rp>(</rp><rt>すう</rt><rp>)</rp></ruby>',
            count: readingStatus?.totalReadingPage
              ? readingStatus.totalReadingPage
              : 0,
            unit: 'ページ',
          }"
          :secondPage="{
            backgroundImage: require('@/assets/image/top/book_books.svg'),
            title:
              '<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>んだ本の<ruby><rb>数</rb><rp>(</rp><rt>かず</rt><rp>)</rp></ruby>',
            count: readingStatus?.readingCompleteCount
              ? readingStatus.readingCompleteCount
              : 0,
            unit: 'さつ',
          }"
          :loaded="isLoadComplete"
        />
      </div>
      <div class="search">
        <div
          class="search__inner"
          :class="showStatus.search ? 'anime-popIn is-active' : 'anime-popIn'"
        >
          <div class="search__input">
            <div class="search__head">
              <div class="search__simple">
                <div class="search__simple_input" ref="focusInput">
                  <AppInput
                    id="search-free-word"
                    v-model="freeWord"
                    :errorMessages="freeWordErrorMessage ? [''] : []"
                    placeholder="キーワードを入力"
                    @enter="search"
                  />
                </div>
                <div class="search__simple_button">
                  <AppTooltip
                    :enabled="!!freeWordErrorMessage"
                    :text="freeWordErrorMessage"
                    location="bottom"
                  >
                    <template v-slot:default="{ listeners }">
                      <button
                        class="searchButton animationLink"
                        v-on="listeners"
                        @click="search"
                      >
                        <AppButton>
                          <div class="searchButton__inner">
                            <span class="searchButton__icon"></span>
                            <span class="searchButton__text">さがす</span>
                          </div>
                        </AppButton>
                      </button>
                    </template>
                  </AppTooltip>
                </div>
              </div>
              <div class="search__advanced">
                <button
                  class="searchButton animationLink"
                  @click="openSearchDetails"
                >
                  <AppButton>
                    <div class="searchButton__inner">
                      <span class="searchButton__icon"></span>
                      <span class="searchButton__text">くわしくさがす</span>
                    </div>
                  </AppButton>
                </button>
              </div>
            </div>
            <div class="search__grade">
              <ul class="search__grade_lists">
                <li class="search__grade_list">
                  <router-link
                    :to="`/book_search?searchType=grade&grade=1&order=${ORDERS.nOrderReleaseStart.code}`"
                    class="search__grade_link animationLink"
                  >
                    <AppButton color="grade">
                      <div class="search__grade_button">1・2ねんせい</div>
                    </AppButton>
                  </router-link>
                </li>
                <li class="search__grade_list">
                  <router-link
                    :to="`/book_search?searchType=grade&grade=2&order=${ORDERS.nOrderReleaseStart.code}`"
                    class="search__grade_link animationLink"
                  >
                    <AppButton color="grade">
                      <div class="search__grade_button">3・4年生</div>
                    </AppButton>
                  </router-link>
                </li>
                <li class="search__grade_list">
                  <router-link
                    :to="`/book_search?searchType=grade&grade=3&order=${ORDERS.nOrderReleaseStart.code}`"
                    class="search__grade_link appButton__link animationLink"
                  >
                    <AppButton color="grade">
                      <div class="search__grade_button">5・6年生</div>
                    </AppButton>
                  </router-link>
                </li>
                <li class="search__grade_list">
                  <router-link
                    :to="`/book_search?searchType=grade&grade=4&order=${ORDERS.nOrderReleaseStart.code}`"
                    class="search__grade_link appButton__link animationLink"
                  >
                    <AppButton color="grade">
                      <div class="search__grade_button">中・高校生</div>
                    </AppButton>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="topPage__body">
      <div
        :class="{
          withoutNowRead: !isUserId,
          topPage__body_nextGiga: isNextGiga,
          topPage__body_inner: !isNextGiga,
        }"
      >
        <div
          v-if="!isNextGiga"
          class="feature"
          :class="{ withoutNowRead: !isUserId }"
        >
          <ul class="feature__lists">
            <li
              class="feature__list"
              :class="
                showStatus.feature1 ? 'anime-popIn is-active' : 'anime-popIn'
              "
              @click="onClickFeature(featureData[0])"
            >
              <FeatureBanner v-if="featureData[0]" :feature="featureData[0]" />
            </li>
            <li
              class="feature__list"
              :class="
                showStatus.feature2 ? 'anime-popIn is-active' : 'anime-popIn'
              "
              @click="onClickFeature(featureData[1])"
            >
              <FeatureBanner v-if="featureData[1]" :feature="featureData[1]" />
            </li>
            <li
              class="feature__list"
              :class="
                showStatus.feature2 ? 'anime-popIn is-active' : 'anime-popIn'
              "
              @click="onClickFeature(featureData[2])"
            >
              <FeatureBanner v-if="featureData[2]" :feature="featureData[2]" />
            </li>
          </ul>
        </div>
        <div
          class="ranking"
          :class="[
            showStatus.ranking ? 'anime-popIn is-active' : 'anime-popIn',
            !isUserId ? 'withoutNowRead' : '',
          ]"
          @click="GaHelper.registerGaEvent(GA_EVENT_VALUES.top.ranking)"
        >
          <router-link to="/book_ranking" class="ranking__link banner__link">
            <div class="banner__wrap">
              <div class="banner__item ranking__item">
                <div class="ranking__item_inner">
                  <p class="ranking__item_text">みんなの<br />ランキング</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="today"
          :class="[
            showStatus.today ? 'anime-popIn is-active' : 'anime-popIn',
            !isUserId ? 'withoutNowRead' : '',
          ]"
        >
          <button class="today__link banner__link" @click="onClickBookCapsule">
            <div class="banner__wrap">
              <div class="banner__item today__item">
                <div class="today__item_inner">
                  <p class="today__item_text">きょうの<br />1さつ</p>
                </div>
              </div>
            </div>
          </button>
        </div>
        <AppTooltip
          :enabled="(!canRead && isReadingContentsId) || !enabledNowRead"
          text="<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>むことができません"
          location="top"
          :class="showStatus.nowRead ? 'anime-popIn is-active' : 'anime-popIn'"
          class="nowRead"
        >
          <template v-slot:default="{ listeners }">
            <div
              v-if="isUserId"
              v-on="listeners"
              :class="
                enabledNowRead
                  ? 'nowRead__link banner__link'
                  : 'nowRead__static is-inactive'
              "
              @click="onClickRead"
            >
              <div class="banner__wrap">
                <div class="banner__item nowRead__item">
                  <div class="nowRead__item_inner">
                    <div class="nowRead__item_box">
                      <p class="nowRead__item_text">
                        <ruby><rb>今</rb><rp>(</rp><rt>いま</rt><rp>)</rp></ruby
                        ><ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby
                        >んで<br class="pcOnly" />いる本
                      </p>
                      <div
                        class="nowRead__item_btn"
                        :class="{ 'is-disabled': !enabledNowRead }"
                      >
                        <ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby
                        >む
                      </div>
                    </div>
                    <div
                      class="nowRead__item_book"
                      :class="{
                        'is-inactive':
                          !readingStatus?.readingContentsId ||
                          readingStatus?.cancelStatus ===
                            CANCEL_STATUS.canceled.value,
                      }"
                    >
                      <AppImage
                        :src="
                          nowReadBookThumnailPath
                            ? nowReadBookThumnailPath
                            : require('@/assets/image/img_book_none.svg')
                        "
                        :noImageSrc="require('@/assets/image/img_book_404.svg')"
                        width="120"
                        height="120"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </AppTooltip>
        <div
          class="recommended"
          :class="
            showStatus.recommended ? 'anime-popIn is-active' : 'anime-popIn'
          "
        >
          <h2 v-if="isNextGiga" class="recommended__title recommended__title_nextgiga">
            <ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>むことができる本
          </h2>
          <h2 v-else class="recommended__title">
            <ruby><rb>新</rb><rp>(</rp><rt>あたら</rt><rp>)</rp></ruby>しく<ruby
              ><rb>入</rb><rp>(</rp><rt>はい</rt><rp>)</rp></ruby
            >った本
          </h2>

          <div class="recommended__body">
            <ul v-if="bookList?.length" class="recommended__lists">
              <li
                v-for="book in bookList"
                :key="book.contentsId"
                class="recommended__list"
              >
                <Book
                  :contentsId="book.contentsId"
                  :newBookFlg="book.newBookFlg"
                  :thumbnailPath="book.thumbnailPath"
                  :hasAction="false"
                  pattern="small"
                  class="card"
                  @click:book="onClickBook"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>

  <transition name="modal">
    <div v-if="isVisibleBookCapsule" class="bookCapsule__wrap">
      <BookCapsule @close="closeBookCapsule" />
    </div>
  </transition>

  <transition name="modal">
    <div v-if="isVisibleSearchDetails">
      <SearchDetails @close="onCloseSearchDetails" />
    </div>
  </transition>

  <transition name="modal">
    <div v-if="isVisibleNotifications" class="notifications__wrap">
      <Notifications
        :notifications="notificationList"
        @close="closeNotifications"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { inject, nextTick, ref, reactive, computed, onMounted, Ref } from 'vue'
import { useRouter } from 'vue-router'

import { useReadBook } from '@/composables/useReadBook'
import { useHandleError } from '@/composables/useHandleError'
import { CANCEL_STATUS } from '@/constants'
import { GA_EVENT_VALUES } from '@/constants/ga'
import { ORDERS } from '@/constants/search'
import { injectionKeys } from '@/constants/injectionKeys'
import GaHelper from '@/helpers/GaHelper'
import ValidationHelper from '@/helpers/ValidationHelper'

import {
  GetFeatureItem,
  GetYmNotificationResponseItem,
  ReadingStatusResponse,
  GetPickupItem,
  PickupList,
  GetFeatureRequest,
} from '@/models/ApiIF'

import {
  generateNumbers,
  randomNumbersNotDuplicated,
  shuffleArray,
} from '@/utils'
import { useErrorStore, BOOKSHELF_REGISTRATION } from '@/store/error'
import { useKeyboardStore } from '@/store/keyboard'
import { useLoadingStore } from '@/store/loading'

import AppButton from '@/components/AppButton/AppButton.vue'
import AppImage from '@/components/AppImage/AppImage.vue'
import AppInput from '@/components/AppInput/AppInput.vue'
import AppTooltip from '@/components/AppTooltip/AppTooltip.vue'
import Book from '@/components/Book/Book.vue'
import FeatureBanner from '@/components/FeatureBanner/FeatureBanner.vue'
import Notifications from '@/components/Notifications/Notifications.vue'
import ReadBookStatus from '@/components/ReadBookStatus/ReadBookStatus.vue'
import SearchDetails from '@/components/SearchDetails/SearchDetails.vue'
import BookCapsule from '@/components/BookCapsule/BookCapsule.vue'
import InputSettings from '@/models/InputSettings'
import ApiHelper from '@/helpers/ApiHelper'
import StoreHelper from '@/helpers/StoreHelper'
import AppEnum from '@/enums/AppEnum'
import CookieHelper from '@/helpers/CookieHelper'
import LocalStorageHelper from '@/helpers/LocalStorageHelper'
import ApiException, { exceptions } from '@/exceptions/ApiException'

// provide/inject, routerなどの共通系
const isUserId = inject<Ref<boolean>>(injectionKeys.isUserId)!

// check is next giga
const isNextGiga = inject<Ref<boolean>>(injectionKeys.isNextGiga)!

const loadingStore = useLoadingStore()
const $validation = inject<ValidationHelper>(injectionKeys.$validation)!
const { handleErrors } = useHandleError()

const router = useRouter()

// 要素の遅延表示
interface ShowStatus {
  ReadBookStatus: boolean
  search: boolean
  feature1: boolean
  feature2: boolean
  feature3: boolean
  ranking: boolean
  today: boolean
  nowRead: boolean
  recommended: boolean
  [key: string]: boolean
}
const showStatus = reactive({
  ReadBookStatus: false,
  search: false,
  feature1: false,
  feature2: false,
  feature3: false,
  ranking: false,
  today: false,
  nowRead: false,
  recommended: false,
}) as ShowStatus

let showTime = ref(200)

// 表示アニメーション
const showAnimation = () => {
  Object.keys(showStatus).forEach((key) => {
    showTime.value = showTime.value + 100
    setTimeout(() => {
      showStatus[key] = true
    }, showTime.value)
  })
}

// APIとDOMの読み込み状態
const loadDataAndDomState = ref({
  isLoadedApi: false,
  isLoadedDom: false,
})
const isLoadComplete = computed(() => {
  return (
    loadDataAndDomState.value.isLoadedApi &&
    loadDataAndDomState.value.isLoadedDom
  )
})

// 読み込み完了確認
const checkPageLoading = async () => {
  if (isLoadComplete.value) {
    // ローディング終了
    loadingStore.setLoading(false)
    // 表示
    showAnimation()
  }
}

// お知らせ
const notificationList = ref<GetYmNotificationResponseItem[]>([])
const isVisibleNotifications = ref(false)
const openNotifications = () => {
  isVisibleNotifications.value = true
}
const closeNotifications = () => {
  isVisibleNotifications.value = false
}

// フリーワード検索
const freeWord = ref('')

// さがす
const search = () => {
  if (freeWordErrorMessage.value) {
    return
  }

  const query: { [key: string]: string | number } = {
    searchType: 'freeword',
    freeWord: freeWord.value,
  }
  if (!freeWord.value) {
    query.order = ORDERS.nOrderReleaseStart.code
  }

  router.push({
    path: '/book_search',
    query,
  })
}

// くわしくさがす
const isVisibleSearchDetails = ref(false)
const openSearchDetails = () => {
  isVisibleSearchDetails.value = true
}
const closeSearchDetails = () => {
  isVisibleSearchDetails.value = false
}
const onCloseSearchDetails = () => {
  closeSearchDetails()
  if (keyboardStore.isShow) {
    focusToInput()
  }
}

// 読書状況
const readingStatus = ref<ReadingStatusResponse['readingStatus']>()
const enabledNowRead = computed(() => {
  if (!readingStatus.value) return false
  if (!readingStatus.value.readingContentsId) return false
  if (isNextGiga.value && readingStatus.value.displayStatusNextGiga == 0) {
    return false
  }
  if (readingStatus.value.cancelStatus !== CANCEL_STATUS.notCanceled.value)
    return false
  if (readingStatus.value.releaseEndFlag === 1) return false
  return true
})
const nowReadBookThumnailPath = computed(() => {
  if (!readingStatus.value) return null
  if (!readingStatus.value.readingContentsId) {
    return require('@/assets/image/top/book_starter.svg')
  }
  if (readingStatus.value.cancelStatus === CANCEL_STATUS.canceled.value) {
    return require('@/assets/image/img_book_none.svg')
  }
  return readingStatus.value.thumbnailPath
})
const canRead = computed(() => {
  if (!readingStatus.value) return false
  if (readingStatus.value.cancelStatus !== CANCEL_STATUS.notCanceled.value)
    return false
  if (readingStatus.value.releaseEndFlag === 1) return false
  if (readingStatus.value.readingContentsId) return true
  return true
})
const isReadingContentsId = computed(() => {
  if (!readingStatus.value) return false
  if (readingStatus.value.readingContentsId) return true
  return false
})

const { read, startPollingCheckReadingComplete } = useReadBook()
const errorStore = useErrorStore()
const onClickRead = async () => {
  if (!enabledNowRead.value) return
  if (!readingStatus.value?.readingContentsId) return
  read(readingStatus.value.readingContentsId)
  GaHelper.registerGaEvent(GA_EVENT_VALUES.top.readingBook)
  const errorCode = await registerBookToBookshelf()
  if (errorCode) {
    errorStore.setErrorCode(BOOKSHELF_REGISTRATION, errorCode)
  }
  startPollingCheckReadingComplete()
}

// 特集
const featureData = ref<GetFeatureItem[]>([])
const onClickFeature = (feature: GetFeatureItem) => {
  const dimensions = ['top', `${feature.id}${feature.title}`]
  return GaHelper.registerGaEvent(GA_EVENT_VALUES.feature.banner, dimensions)
}

// きょうの1さつ
const isVisibleBookCapsule = ref(false)
const onClickBookCapsule = () => {
  openBookCapsule()
  GaHelper.registerGaEvent(GA_EVENT_VALUES.top.bookCapsule)
}
const openBookCapsule = () => {
  isVisibleBookCapsule.value = true
}
const closeBookCapsule = () => {
  isVisibleBookCapsule.value = false
}

// 新しく入った本
const pickupList = ref<PickupList>({ bookList: [] })

const MAX_BOOK_LIST = 5
const bookList = computed(() => {
  if (!pickupList.value) return []
  if (!pickupList.value.bookList) return []

  // ランダム数値(index)を5コ生成
  const randomNumbers = randomNumbersNotDuplicated(
    generateNumbers(0, pickupList.value.bookList.length - 1),
    pickupList.value.bookList.length < MAX_BOOK_LIST
      ? pickupList.value.bookList.length
      : MAX_BOOK_LIST
  )
  const bookList: GetPickupItem[] = []
  for (const i of randomNumbers) {
    bookList.push(pickupList.value.bookList[i])
  }
  // シャッフル
  return shuffleArray(bookList)
})

const onClickBook = (id: GetPickupItem['contentsId']) => {
  GaHelper.registerGaEvent(GA_EVENT_VALUES.top.pickup, [id])
}

// ソフトキーボードフォーカス設定
const focusInput = ref()
const focusToInput = () => {
  focusInput.value.querySelector('input').focus()
}
const keyboardStore = useKeyboardStore()
keyboardStore.$subscribe(async (mutation, state) => {
  if (state.isShow) {
    await nextTick()
    focusToInput()
  }
})

onMounted(() => {
  // ロードフラグをtrueにする
  loadDataAndDomState.value.isLoadedDom = true
  checkPageLoading()
})

// ↓ 主にTF様の編集領域
// ------------------------------

const inputSettings: InputSettings = {
  freeword: {
    name: 'フリーワード',
    rules: {
      checkEmoji: true,
      maxLength: 129,
    },
  },
}

/**
 * お知らせ取得
 */
const getYmNotification = async () => {
  await ApiHelper.getYmNotification({})
    .then((res) => (notificationList.value = res.notifications))
    .then(() => {
      if (notificationList.value.length > 0) {
        openNotifications()
      }
    })
}

/**
 * 読書状況取得
 */
const getReadingStatus = async () => {
  await ApiHelper.getReadingStatus({}).then((res) => {
    readingStatus.value = res.readingStatus
  })
}

/**
 * ポイント更新
 */
const updatePoint = async () => {
  await ApiHelper.getPoint({}).then((res) => {
    StoreHelper.updatePoint(res.pointBalance)
  })
}

/**
 * 特集取得
 */
const getFeature = async () => {
  const req: GetFeatureRequest = {
    topDisplayFlag: AppEnum.topDisplayFlag.yomokkaTop.value,
    lmt: 3,
  }
  await ApiHelper.getFeature(req).then(
    (res) => (featureData.value = res.featureData)
  )
}

/**
 * ピックアップ取得
 */
const getPickupList = async () => {
  await ApiHelper.getPickupList({}).then((res) => (pickupList.value = res))
}

/**
 * ログイン後初回のみ実施
 */
const execOnlyInitialAfterLogin = async () => {
  if (CookieHelper.getYmLoginFlag()) return

  LocalStorageHelper.setViewerData()

  if (!isNextGiga.value) {
    const callGetYmNotification = getYmNotification()
    await callGetYmNotification
  }
  // const callGetYmNotification = getYmNotification()

  CookieHelper.setYmLoginFlag(true)

  // await callGetYmNotification
}

/**
 * ID認証ユーザの場合のみ実施する
 */
const execOnlyUserId = async () => {
  if (!isUserId.value) return
  const callGetReadingStatus = getReadingStatus()
  const callUpdatePoint = updatePoint()

  await callGetReadingStatus
  await callUpdatePoint
}

// 初期ロード時のAPIリクエスト
const initialRequest = async () => {
  const callOnlyInitialAfterLogin = execOnlyInitialAfterLogin()
  const callOnlyUserId = execOnlyUserId()
  const callGetFeature = getFeature()
  const callGetPickupList = getPickupList()

  await callOnlyInitialAfterLogin
  await callOnlyUserId
  await callGetFeature
  await callGetPickupList

  // ロードフラグをtrueにする
  loadDataAndDomState.value.isLoadedApi = true
  checkPageLoading()
}

const init = (async () => {
  // ローディング開始
  loadingStore.setLoading(true)
  try {
    // 初期表示用のリクエスト
    await initialRequest()
  } catch (err) {
    loadingStore.setLoading(false)
    handleErrors(err)
  }
})()

// バリデーション
const freeWordErrorMessage = computed(() => {
  return $validation.getErrorMessage(
    freeWord.value,
    inputSettings,
    'freeword',
    true,
    true
  )
})

const registerBookToBookshelf = async () => {
  try {
    await ApiHelper.registerBookshelfBook({
      contentsId: readingStatus.value!.readingContentsId!,
    })
    return null
  } catch (err) {
    if (!(err instanceof ApiException)) {
      handleErrors(err)
    }
    const errorCode = err.code
    if (errorCode === exceptions.data.alreadyProcessed.code) {
      return null
    }
    if (
      errorCode === exceptions.data.bookMaxError.code ||
      errorCode === exceptions.data.bookPositionMaxError.code
    ) {
      return errorCode
    }
    handleErrors(err)
  }
}
</script>

<style lang="scss" scoped>
@import 'Top.scss';
</style>
