<template>
  <SlotModal
    :icon="require('@/assets/image/icon/icon_search_advanced.svg')"
    @close="close"
  >
    <template v-slot:title>
      <div class="searchDetails__head">
        <p class="searchDetails__head_title">くわしくさがす</p>
        <AppCheckbox
          class="searchDetails__head_checkbox"
          name="searchDetails-area"
          displayName="<ruby><rb>地域</rb><rp>(</rp><rt>ちいき</rt><rp>)</rp></ruby>の<ruby><rb>資料</rb><rp>(</rp><rt>しりょう</rt><rp>)</rp></ruby>のみをさがす"
          :checked="area"
          @change="onChangeArea"
        />
      </div>
    </template>

    <div class="searchDetails__contents">
      <div class="searchDetails__fieldArea">
        <div class="searchDetails__group">
          <div class="searchDetails__name">タイトル</div>
          <div class="searchDetails__item" ref="focusInput">
            <AppInput
              id="search-title"
              v-model="title"
              :errorMessages="titleErrorMessage"
              @enter="search"
            />
          </div>
        </div>
        <div class="searchDetails__group" :class="disabledItem.className">
          <div class="searchDetails__name">
            <ruby
              ><rb>著&emsp;者</rb><rp>(</rp><rt>ちょしゃ</rt><rp>)</rp></ruby
            >
          </div>
          <div class="searchDetails__item">
            <AppInput
              id="search-author"
              v-model="author"
              :errorMessages="authorErrorMessage"
              @enter="search"
            />
          </div>
        </div>
        <div class="searchDetails__group" :class="disabledItem.className">
          <div class="searchDetails__name">
            <ruby
              ><rb>出&nbsp;&nbsp;&nbsp;版&nbsp;&nbsp;&nbsp;社</rb><rp>(</rp
              ><rt>しゅっぱんしゃ</rt><rp>)</rp></ruby
            >
          </div>
          <div class="searchDetails__item" :class="disabledItem.className">
            <AppSelect
              v-if="publisherList.length"
              v-model="publisher"
              itemValue="publisherId"
              itemTitle="publisherName"
              :items="publisherList"
            />
          </div>
        </div>
        <div class="searchDetails__group" :class="disabledItem.className">
          <div class="searchDetails__name">
            <ruby
              ><rb>刊&nbsp;&nbsp;行&nbsp;&nbsp;年</rb><rp>(</rp
              ><rt>かんこうねん</rt><rp>)</rp></ruby
            >
          </div>
          <div class="searchDetails__dateWrapper">
            <div class="searchDetails__date">
              <div class="searchDetails__date_input">
                <AppInput
                  id="search-date-from"
                  v-model="dateFrom"
                  :errorMessages="
                    dateBetweenErrorMessage || dateFromErrorMessage ? [''] : []
                  "
                  @enter="search"
                />
              </div>
              <span class="searchDetails__date_text"
                ><ruby><rb>年</rb><rp>(</rp><rt>ねん</rt><rp>)</rp></ruby
                >〜</span
              >
              <div class="searchDetails__date_input">
                <AppInput
                  id="search-date-to"
                  v-model="dateTo"
                  :errorMessages="
                    dateBetweenErrorMessage || dateToErrorMessage ? [''] : []
                  "
                  @enter="search"
                />
              </div>
              <span class="searchDetails__date_text"
                ><ruby><rb>年</rb><rp>(</rp><rt>ねん</rt><rp>)</rp></ruby></span
              >
            </div>
            <div
              v-if="
                dateBetweenErrorMessage ||
                dateFromErrorMessage ||
                dateToErrorMessage
              "
              class="searchDetails__date_error"
            >
              <div
                v-if="dateBetweenErrorMessage"
                v-html="dateBetweenErrorMessage"
              />
              <div
                v-else-if="dateFromErrorMessage"
                v-html="dateFromErrorMessage"
              />
              <div v-else-if="dateToErrorMessage" v-html="dateToErrorMessage" />
            </div>
          </div>
        </div>
      </div>
      <div class="searchDetails__group" :class="disabledItem.className">
        <div class="searchDetails__name searchDetails__name--radioGroup">
          <ruby><rb>学 年</rb><rp>(</rp><rt>がくねん</rt><rp>)</rp></ruby>
        </div>
        <div class="searchDetails__radioGroup">
          <RadioGroup
            :currentValue="grade"
            groupName="grade"
            groupColor="cyan"
            :list="gradeList"
            @change="onChangeGrade"
          />
        </div>
      </div>
      <div
        class="searchDetails__group"
        v-if="firstLayers.length"
        :class="disabledItem.className"
      >
        <div
          class="searchDetails__name searchDetails__name--radioGroup searchDetails__name--firstLayers"
        >
          ジャンル
        </div>
        <div class="searchDetails__radioGroup">
          <RadioGroup
            v-if="firstLayers.length"
            :currentValue="first"
            groupName="first"
            groupColor="red"
            :list="firstLayers"
            @change="onChangeFirst"
          />
        </div>
      </div>
      <div
        class="searchDetails__group"
        v-if="firstLayers.length"
        :class="disabledItem.className"
      >
        <div class="searchDetails__name searchDetails__name--radioGroup">
          くわしい<br />ジャンル
        </div>
        <div class="searchDetails__radioGroup">
          <RadioGroup
            :currentValue="second"
            groupName="second"
            groupColor="purple"
            :list="secondaryLayers"
            @change="onChangeSecond"
          />
        </div>
      </div>
      <div class="searchDetails__group" :class="disabledItem.className">
        <div class="searchDetails__name searchDetails__name--radioGroup">
          <ruby><rb>音 声</rb><rp>(</rp><rt>おんせい</rt><rp>)</rp></ruby
          ><br /><ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>み<ruby
            ><rb>上</rb><rp>(</rp><rt>あ</rt><rp>)</rp></ruby
          >げ
        </div>
        <div class="searchDetails__radioGroup">
          <RadioGroup
            :currentValue="contentsStyle"
            groupName="contentsStyle"
            groupColor="green"
            :list="contentsStyleList"
            @change="onChangeContentsStyle"
          />
        </div>
      </div>
    </div>

    <template v-slot:actionArea>
      <div class="searchDetails__actionArea">
        <button class="searchDetails__button animationLink" @click="search">
          <AppButton tag="span" shadow="m" rounded="pill">
            <span class="searchDetails__button_inner">
              <span class="searchDetails__button_icon">
                <AppImage
                  :src="require('@/assets/image/icon/icon_search_advanced.svg')"
                  alt=""
                  :width="32"
                  :height="32"
                />
              </span>
              <span class="searchDetails__button_text">さがす</span>
            </span>
          </AppButton>
        </button>
        <AppCheckbox
          class="searchDetails__actionArea__checkbox"
          :class="disabledItem.className"
          name="searchDetails-learning"
          displayName="<ruby>物語<rp>(<rt>ものがたり<rp>)</ruby><ruby>以外<rp>(<rt>いがい<rp>)</ruby>の<br class='spOnly'>本をさがす"
          :checked="learning"
          @change="onChangeLearning"
        />
      </div>
    </template>
  </SlotModal>

  <transition name="dialog">
    <div v-if="errorDialog.isVisible.value" class="dialogArea">
      <SlotDialog
        type="error"
        title="<ruby><rb>入力</rb><rp>(</rp><rt>にゅうりょく</rt><rp>)</rp></ruby>した<ruby><rb>内容</rb><rp>(</rp><rt>ないよう</rt><rp>)</rp></ruby>をもう<ruby><rb>一度</rb><rp>(</rp><rt>いちど</rt><rp>)</rp></ruby><br>かくにんしてください"
        @close="errorDialog.close"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
/**
 * SearchDetails.
 *
 * くわしくさがす.
 */

import {
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
  ref,
  computed,
  inject,
} from 'vue'
import { useRouter } from 'vue-router'

import { useDialog } from '@/composables/useDialog'
import {
  Grade,
  GRADE_LIST,
  ContentsStyle,
  CONTENTS_STYLE_LIST,
} from '@/constants'
import { ORDERS } from '@/constants/search'
import ValidationHelper from '@/helpers/ValidationHelper'
import { useKeyboardStore } from '@/store/keyboard'

import {
  GetPublisherItem,
  GetFirstLayerResponseItem,
  GetSecondaryLayerResponseItem,
} from '@/models/ApiIF'

import AppButton from '@/components/AppButton/AppButton.vue'
import AppCheckbox from '@/components/AppCheckbox/AppCheckbox.vue'
import AppInput from '@/components/AppInput/AppInput.vue'
import AppImage from '@/components/AppImage/AppImage.vue'
import AppSelect from '@/components/AppSelect/AppSelect.vue'
import RadioGroup from '@/components/RadioGroup/RadioGroup.vue'
import SlotModal from '@/components/SlotModal/SlotModal.vue'
import SlotDialog from '../SlotDialog/SlotDialog.vue'
import ApiHelper from '@/helpers/ApiHelper'
import { useHandleError } from '@/composables/useHandleError'
import InputSettings from '@/models/InputSettings'
import { injectionKeys } from '@/constants/injectionKeys'
import StoreHelper from '@/helpers/StoreHelper'

const { handleErrors } = useHandleError()
const $validation = inject<ValidationHelper>(injectionKeys.$validation)

const router = useRouter()

const emit = defineEmits(['close'])

// 地域の資料のみをさがす
const area = ref(false)
const onChangeArea = (params: { name: string; checked: boolean }) => {
  area.value = params.checked
}

const disabledItem = computed(() => {
  return {
    className: {
      disabled: area.value,
    },
  }
})

// 物語以外の本をさがす
const learning = ref(false)
const onChangeLearning = (params: { name: string; checked: boolean }) => {
  learning.value = params.checked
}

const title = ref('') // タイトル
const author = ref('') // 著者
const dateFrom = ref('') // 刊行年(From)
const dateTo = ref('') // 刊行年(To)

// 出版社
const publisherList = ref<GetPublisherItem[]>([])
const publisher = ref<GetPublisherItem['publisherId']>(null)

// 学年
const gradeList = GRADE_LIST
const grade = ref<Grade['code']>(gradeList[0].code)
const onChangeGrade = (params: Grade) => {
  grade.value = params.code
}

// ジャンル
const firstLayers = ref<GetFirstLayerResponseItem[]>([])
const first = ref<GetFirstLayerResponseItem['code']>(null)
const onChangeFirst = async (params: GetFirstLayerResponseItem) => {
  first.value = params.code
  await getSecond(params)
  second.value = secondaryLayers.value[0]?.code ?? null
}

// くわしいジャンル
const secondaryLayers = ref<GetSecondaryLayerResponseItem[]>([])
const second = ref<GetSecondaryLayerResponseItem['code']>(null)
const onChangeSecond = (params: GetSecondaryLayerResponseItem) => {
  second.value = params.code
}

// 音声読み上げ
const contentsStyleList = CONTENTS_STYLE_LIST
const contentsStyle = ref<ContentsStyle['code']>(contentsStyleList[0].code)
const onChangeContentsStyle = (params: ContentsStyle) => {
  contentsStyle.value = params.code
}

// エラーダイアログ
const errorDialog = useDialog()

// さがす
const search = () => {
  if (!isValidAll.value) {
    errorDialog.open()
    return
  }

  const path = '/book_search'
  const searchType = 'detail'
  const query: { [key: string]: string | number | null } = {}

  query.searchType = searchType
  query.title = title.value

  if (!title.value && !author.value) {
    query.order = ORDERS.nOrderReleaseStart.code
  }

  if (area.value) {
    query.area = Number(area.value)
  } else {
    query.author = author.value
    query.dateTo = dateTo.value
    query.dateFrom = dateFrom.value
    query.grade = grade.value
    query.first = first.value
    query.second = second.value
    query.publisher = publisher.value
    query.contentsStyle = contentsStyle.value
    query.learning = Number(learning.value)
  }

  router.push({ path, query })
}

const isValidAll = computed(() => {
  return (
    !titleErrorMessage.value &&
    !authorErrorMessage.value &&
    !dateFromErrorMessage.value &&
    !dateToErrorMessage.value &&
    !dateBetweenErrorMessage.value
  )
})

// とじる
const close = () => {
  emit('close')
}

// ソフトキーボードフォーカス設定
const focusInput = ref()
const focusToInput = () => {
  focusInput.value.querySelector('input').focus()
}
const keyboardStore = useKeyboardStore()
keyboardStore.$subscribe(async (mutation, state) => {
  if (state.isShow) {
    await nextTick()
    focusToInput()
  }
})

onMounted(() => {
  if (keyboardStore.isShow) {
    focusToInput()
  }
})

// ↓ 主にTF様の編集領域
// ------------------------------

const inputSettings: InputSettings = {
  title: {
    name: 'タイトル',
    rules: {
      checkEmoji: true,
      maxLength: 100,
    },
  },
  author: {
    name: '著者',
    rules: {
      checkEmoji: true,
      maxLength: 100,
    },
  },
  dateFrom: {
    name: '刊行年(from)',
    rules: {
      number: true,
      exactLength: 4,
    },
  },
  dateTo: {
    name: '刊行年(to)',
    rules: {
      number: true,
      exactLength: 4,
    },
  },
}

// くわしいジャンル取得
type selectedFirstLayer = GetFirstLayerResponseItem
const secondaryLayersMap = new Map<
  string | null,
  GetSecondaryLayerResponseItem[]
>()
const getSecond = async (firstLayer: selectedFirstLayer) => {
  if (secondaryLayersMap.has(firstLayer.code)) {
    secondaryLayers.value = secondaryLayersMap.get(firstLayer.code)!
    return
  }

  secondaryLayers.value = []
  let resSecondaryLayers: GetSecondaryLayerResponseItem[] = []
  if (firstLayer.code !== null) {
    const req = {
      firstLayerCode: firstLayer.code!,
    }
    await ApiHelper.getSecondaryLayer(req)
      .then((res) => {
        resSecondaryLayers = res.secondaryLayers
      })
      .catch((err) => {
        handleErrors(err)
      })
  }
  // 初期値を設定
  resSecondaryLayers.unshift({
    code: null,
    name: 'すべて',
    rubyName: '',
  })
  secondaryLayersMap.set(firstLayer.code, resSecondaryLayers)
  // ジャンルを変更していた場合、画面表示に設定しない
  if (first.value === firstLayer.code) {
    secondaryLayers.value = resSecondaryLayers
  }
}

const initialRequest = async () => {
  firstLayers.value = (await StoreHelper.getAndSetFirstLayerCode())!
  first.value = firstLayers.value[0].code
  getSecond(firstLayers.value[0])

  publisherList.value = (await StoreHelper.getAndSetPublisherList())!
  publisher.value = publisherList.value[0].publisherId
}

const init = (async () => {
  try {
    // 初期表示用のリクエスト
    await initialRequest()
  } catch (err) {
    handleErrors(err)
  }
})()

// タイトルのバリデーション
const titleErrorMessage = computed(() => {
  return $validation?.getErrorMessage(
    title.value,
    inputSettings,
    'title',
    true,
    false
  )
})

// 著者のバリデーション
const authorErrorMessage = computed(() => {
  return $validation?.getErrorMessage(
    author.value,
    inputSettings,
    'author',
    true,
    false
  )
})

// 刊行年(from)のバリデーション
const dateFromErrorMessage = computed(() => {
  return $validation?.getErrorMessage(
    dateFrom.value,
    inputSettings,
    'dateFrom',
    true,
    false
  )
})

// 刊行年(to)のバリデーション
const dateToErrorMessage = computed(() => {
  return $validation?.getErrorMessage(
    dateTo.value,
    inputSettings,
    'dateTo',
    true,
    false
  )
})

// 刊行年(from)と刊行年(to)のバリデーション
const dateBetweenErrorMessage = computed(() => {
  if (!dateFrom.value || !dateTo.value) return ''
  if (dateFromErrorMessage.value || dateToErrorMessage.value) return ''

  return Number(dateFrom.value) > Number(dateTo.value)
    ? '刊行年がまちがっています<br>左の刊行年 ＜ 右の刊行年で入力してください'
    : ''
})
</script>

<style lang="scss" scoped>
@import 'SearchDetails';
</style>
