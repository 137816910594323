import { ConfigType, EnvConfigType, CommonConfigType } from './ConfigType'

const env = process.env.VUE_APP_ENV ?? 'local'

const envConfig: EnvConfigType = require(`./config-${env}`).default
// const envConfig: EnvConfigType = await import(`./config-${env}`)

// 環境で変わらない設定をこちらに追加してください。
// ConfigTypeの変更も必要です。
const commonConfig: CommonConfigType = {
  encodeTargetProperties: ['password', 'oldPassword', 'newPassword'],
  termOfServiceChildrenUrl: '/oyakusoku', // おやくそく
  termOfServiceUrl: '/agreement', // 団体利用規約
  aboutAbjUrl: '/abj', // ABJマークについて
  privacyPolicyUrl: 'https://www.poplar.co.jp/privacyPolicy/index.html', // プライバシーポリシー
  websiteAgreementUrl: 'https://www.poplar.co.jp/agreement/index.html',
  allowedIdleMinutes: 60,
  pollingInterval: 5 * 1000, //ポーリング秒数
  mottoSokkaSupport: 'https://cs-mottosokka.poplar.co.jp/supportpage_top', // mottoSokkaサポート
  nextGigaSupportTop:
    'https://cs-mottosokka.poplar.co.jp/nextgiga_supportpage_top',
}

// 共通設定と環境設定をマージします。
// 同じ設定が両方にある場合、環境設定が優先されます。
const config: ConfigType = { ...commonConfig, ...envConfig }

export default config
