import CookieHelper from '@/helpers/CookieHelper'
import { usePortalStore } from '@/store/index'
import ApiHelper from './ApiHelper'
import DbEnum from '@/enums/DbEnum'
import AppEnum from '@/enums/AppEnum'

export default class AuthHelper {
  public static logout() {
    ApiHelper.logout({})
    CookieHelper.setSession(null)
    const portalStore = usePortalStore()
    portalStore.$patch({
      sessionId: null,
      user: null,
    })
  }

  public static async ipCheck(): Promise<boolean> {
    const res = await ApiHelper.isAllowIp({})
    return res.isFixedIp === AppEnum.isFixedIp.permission.value
  }

  public static isUserId(): boolean {
    return usePortalStore().getUser?.idType === DbEnum.idType.userId.value
  }

  public static isNextGiga(): boolean {
    return (
      usePortalStore().getUser?.contractPattern ===
      DbEnum.contractPattern.nextGiga.value
    )
  }

  public static isSharedId(): boolean {
    return usePortalStore().getUser?.idType === DbEnum.idType.connectionId.value
  }

  public static isFixedIp(): boolean {
    return usePortalStore().getUser?.idType === DbEnum.idType.staticIp.value
  }

  public static isWhitelist(): boolean {
    return (
      usePortalStore().getUser?.constraintOption ===
      DbEnum.constraintOption.whitelist.value
    )
  }

  public static forbidRegister(): boolean {
    const user = usePortalStore().getUser

    if (!user) return false

    return user.functionLimitList.includes(
      DbEnum.functionLimitCode.restrictionsWritingReviews.value
    )
  }

  public static forbidPublish(): boolean {
    const user = usePortalStore().getUser

    if (!user) return false

    return user.functionLimitList.includes(
      DbEnum.functionLimitCode.publicRestrictions.value
    )
  }

  /**
   * 管理画面への権限を判定する
   * ユーザ種別が「教育委員会管理者」、「学校管理者」、「先生」、「アドミニストレータ」のいずれか、かつログイン済のみtrue
   * @returns {boolean} true:権限あり、false:権限なし
   */
  public static isPermittedUser(): boolean {
    const user = usePortalStore().getUser
    return (
      user?.idType === DbEnum.idType.userId.value &&
      (user?.userType === DbEnum.userType.schoolBoardManager.value ||
        user?.userType === DbEnum.userType.schoolManager.value ||
        user?.userType === DbEnum.userType.teacher.value ||
        user?.userType === DbEnum.userType.admin.value)
    )
  }

  /**
   * 管理者権限有無を判定する
   * @returns {boolean} true:管理者権限かつログイン済、false:管理者権限以外またはログイン済でない
   */
  public static isAdmin(): boolean {
    return usePortalStore().getUser?.userType == DbEnum.userType.admin.value
  }

  /**
   * ログイン済か判定する
   * @returns {boolean} true:ログイン済、false:ログイン済でない
   */
  public static isLogin(): boolean {
    return usePortalStore().getUser ? true : false
  }

  /**
   * 出版社ユーザか判定
   * @returns true:出版社ユーザである場合 false:そうで無い場合
   */
  public static isPublusher(): boolean {
    return (
      usePortalStore().getUser?.userType === DbEnum.userType.publisher.value
    )
  }

  /**
   * 生徒ユーザか判定
   * @returns true:生徒ユーザである場合 false:そうで無い場合
   */
  public static isStudent(): boolean {
    return usePortalStore().getUser?.userType === DbEnum.userType.student.value
  }

  /**
   * 教育委員会管理者ユーザか判定
   * @returns true:教育委員会管理者ユーザである場合 false:そうで無い場合
   */
  public static isSchoolBoardManager(): boolean {
    return (
      usePortalStore().getUser?.userType ===
      DbEnum.userType.schoolBoardManager.value
    )
  }

  /**
   * ID教育委員会管理者・学校管理者・先生ユーザのいずれかに該当するか判定
   * @returns true:教育委員会管理者・学校管理者・先生のいずれかの場合 false:そうで無い場合
   */
  public static isEducator(): boolean {
    const user = usePortalStore().getUser
    return (
      user?.userType === DbEnum.userType.schoolBoardManager.value ||
      user?.userType === DbEnum.userType.schoolManager.value ||
      user?.userType === DbEnum.userType.teacher.value
    )
  }
}
