export class DbInfo {
  constructor(public key: string, public value: number) {}
}

export class FirstLayer {
  constructor(
    public name: string,
    public code: string,
    public second: SecondaryLayer[]
  ) {}
}

export class SecondaryLayer {
  constructor(public name: string, public code: string) {}
}

export default Object.freeze({
  serviceCode: {
    digitalLibrary: new DbInfo('電子図書館', 1),
    newSearchSystem: new DbInfo('新検索システム', 2),
  },
  usagePattern: {
    municipal: new DbInfo('教育委員会', 1),
    school: new DbInfo('学校', 2),
    admin: new DbInfo('アドミニストレータ', 99),
  },
  usageUnit: {
    contract: new DbInfo('契約', 1),
    contractDetails: new DbInfo('契約詳細', 2),
  },
  contractPattern: {
    userId: new DbInfo('ID認証', 1),
    connectionId: new DbInfo('同時接続ID認証', 2),
    staticIp: new DbInfo('IP認証', 3),
    nextGiga: new DbInfo('ID認証(Lenovo Ed.)', 4),
  },
  idType: {
    userId: new DbInfo('通常ID', 1),
    connectionId: new DbInfo('同時接続ID', 2),
    staticIp: new DbInfo('固定IP', 3),
  },
  contractStatus: {
    unconfirmed: new DbInfo('未確認', 1),
    inProgress: new DbInfo('処理中', 2),
    resolved: new DbInfo('処理済み', 3),
    rejected: new DbInfo('却下', 4),
  },
  cancelStatus: {
    notCanceled: new DbInfo('未キャンセル', 0),
    displayCoverOnly: new DbInfo('表紙のみ表示', 1),
    canceled: new DbInfo('表紙表示無し', 2),
  },
  schoolType: {
    elementary: new DbInfo('小学校', 1),
    juniorHigh: new DbInfo('中学校', 2),
    highSchool: new DbInfo('高等学校', 3),
    nit: new DbInfo('高等専門学校', 4),
    compulsory9YearSchool: new DbInfo('義務教育学校（9年制）', 5),
    compulsory12YearSchool: new DbInfo('義務教育学校（12年制）', 6),
    secondarySchool: new DbInfo('中高一貫校', 7),
  },
  schoolBoard: {
    unconfirmed: new DbInfo('（未指定）', 0),
  },
  schoolFundingType: {
    national: new DbInfo('国立', 1),
    public: new DbInfo('公立', 2),
    private: new DbInfo('私立', 3),
    other: new DbInfo('その他', 99),
  },
  userType: {
    schoolBoardManager: new DbInfo('教育委員会管理者', 1),
    schoolManager: new DbInfo('学校管理者', 2),
    publicLibraryManager: new DbInfo('公共図書館管理者', 3),
    libraryUser: new DbInfo('図書館利用者', 4),
    teacher: new DbInfo('先生', 5),
    student: new DbInfo('生徒', 6),
    publisher: new DbInfo('出版社', 90),
    admin: new DbInfo('アドミニストレータ', 99),
  },
  prefecture: {
    hokkaido: new DbInfo('北海道', 1),
    aomori: new DbInfo('青森県', 2),
    iwate: new DbInfo('岩手県', 3),
    miyagi: new DbInfo('宮城県', 4),
    akita: new DbInfo('秋田県', 5),
    yamagata: new DbInfo('山形県', 6),
    fukushima: new DbInfo('福島県', 7),
    ibaraki: new DbInfo('茨城県', 8),
    tochigi: new DbInfo('栃木県', 9),
    gunma: new DbInfo('群馬県', 10),
    saitama: new DbInfo('埼玉県', 11),
    chiba: new DbInfo('千葉県', 12),
    tokyo: new DbInfo('東京都', 13),
    kanagawa: new DbInfo('神奈川県', 14),
    niigata: new DbInfo('新潟県', 15),
    toyama: new DbInfo('富山県', 16),
    ishikawa: new DbInfo('石川県', 17),
    fukui: new DbInfo('福井県', 18),
    yamanashi: new DbInfo('山梨県', 19),
    nagano: new DbInfo('長野県', 20),
    gifu: new DbInfo('岐阜県', 21),
    shizuoka: new DbInfo('静岡県', 22),
    aichi: new DbInfo('愛知県', 23),
    mie: new DbInfo('三重県', 24),
    shiga: new DbInfo('滋賀県', 25),
    kyoto: new DbInfo('京都府', 26),
    osaka: new DbInfo('大阪府', 27),
    hyogo: new DbInfo('兵庫県', 28),
    nara: new DbInfo('奈良県', 29),
    wakayama: new DbInfo('和歌山県', 30),
    tottori: new DbInfo('鳥取県', 31),
    shimane: new DbInfo('島根県', 32),
    okayama: new DbInfo('岡山県', 33),
    hiroshima: new DbInfo('広島県', 34),
    yamaguchi: new DbInfo('山口県', 35),
    tokushima: new DbInfo('徳島県', 36),
    kagawa: new DbInfo('香川県', 37),
    ehime: new DbInfo('愛媛県', 38),
    kochi: new DbInfo('高知県', 39),
    fukuoka: new DbInfo('福岡県', 40),
    saga: new DbInfo('佐賀県', 41),
    nagasaki: new DbInfo('長崎県', 42),
    kumamoto: new DbInfo('熊本県', 43),
    oita: new DbInfo('大分県', 44),
    miyazaki: new DbInfo('宮崎県', 45),
    kagoshima: new DbInfo('鹿児島県', 46),
    okinawa: new DbInfo('沖縄県', 47),
  },
  sex: {
    male: new DbInfo('男性', 1),
    female: new DbInfo('女性', 2),
    other: new DbInfo('その他', 3),
  },
  disclosureRange: {
    nationWide: new DbInfo('全国', 1),
    municipal: new DbInfo('地域', 2),
    school: new DbInfo('学校内', 3),
    noRegister: new DbInfo('登録不可', 4),
    noPublication: new DbInfo('公開不可', 5),
  },
  gradeCode: {
    elementaryY1Y2: new DbInfo('1・2年生向け', 1),
    elementaryY3Y4: new DbInfo('3・4年生向け', 2),
    elementaryY5Y6: new DbInfo('5・6年生向け', 3),
    juniorHighAbove: new DbInfo('中・高校生以上', 4),
  },
  colorTheme: {
    default: new DbInfo('デフォルト', 1),
    wood: new DbInfo('木目', 2),
    planets: new DbInfo('宇宙', 3),
    stars: new DbInfo('星', 4),
    hearts: new DbInfo('ハート', 5),
    waveGreen: new DbInfo('ウェーブ緑', 6),
    waveBlue: new DbInfo('ウェーブ青', 7),
    waveRed: new DbInfo('ウェーブ赤・ピンク', 8),
    waveYellow: new DbInfo('ウェーブ黄・茶色', 9),
    cyberGreen: new DbInfo('サイバー緑', 10),
    cyberBlue: new DbInfo('サイバー青', 11),
    cyberRed: new DbInfo('サイバー赤・ピンク', 12),
    cyberYellow: new DbInfo('サイバー黄・茶色', 13),
    checkGreen: new DbInfo('チェック緑', 14),
    checkBlue: new DbInfo('チェック青', 15),
    checkRed: new DbInfo('チェック赤・ピンク', 16),
    checkYellow: new DbInfo('チェック黄・茶色', 17),
    stripeGreen: new DbInfo('ストライプ緑', 18),
    stripeBlue: new DbInfo('ストライプ青', 19),
    stripeRed: new DbInfo('ストライプ赤・ピンク', 20),
    stripeYellow: new DbInfo('ストライプ黄・茶色', 21),
    plainGreen: new DbInfo('無地緑', 22),
    plainBlue: new DbInfo('無地青', 23),
    plainRed: new DbInfo('無地赤・ピンク', 24),
    plainYellow: new DbInfo('無地黄・茶色', 25),
  },
  profileImage: {
    avatar1: new DbInfo('プロフィール画像1', 1),
    avatar2: new DbInfo('プロフィール画像2', 2),
    avatar3: new DbInfo('プロフィール画像3', 3),
    avatar4: new DbInfo('プロフィール画像4', 4),
    avatar5: new DbInfo('プロフィール画像5', 5),
    avatar6: new DbInfo('プロフィール画像6', 6),
    avatar7: new DbInfo('プロフィール画像7', 7),
    avatar8: new DbInfo('プロフィール画像8', 8),
    avatar9: new DbInfo('プロフィール画像9', 9),
    avatar10: new DbInfo('プロフィール画像10', 10),
    avatar11: new DbInfo('プロフィール画像11', 11),
    avatar12: new DbInfo('プロフィール画像12', 12),
    avatar13: new DbInfo('プロフィール画像13', 13),
    avatar14: new DbInfo('プロフィール画像14', 14),
    avatar15: new DbInfo('プロフィール画像15', 15),
    avatar16: new DbInfo('プロフィール画像16', 16),
    avatar17: new DbInfo('プロフィール画像17', 17),
    avatar18: new DbInfo('プロフィール画像18', 18),
    avatar19: new DbInfo('プロフィール画像19', 19),
    avatar20: new DbInfo('プロフィール画像20', 20),
    avatar21: new DbInfo('プロフィール画像21', 21),
    avatar22: new DbInfo('プロフィール画像22', 22),
    avatar23: new DbInfo('プロフィール画像23', 23),
    avatar24: new DbInfo('プロフィール画像24', 24),
    avatar25: new DbInfo('プロフィール画像25', 25),
    avatar26: new DbInfo('プロフィール画像26', 26),
    avatar27: new DbInfo('プロフィール画像27', 27),
    avatar28: new DbInfo('プロフィール画像28', 28),
    avatar29: new DbInfo('プロフィール画像29', 29),
    avatar30: new DbInfo('プロフィール画像30', 30),
    avatar31: new DbInfo('プロフィール画像31', 31),
    avatar32: new DbInfo('プロフィール画像32', 32),
    avatar33: new DbInfo('プロフィール画像33', 33),
    avatar34: new DbInfo('プロフィール画像34', 34),
    avatar35: new DbInfo('プロフィール画像35', 35),
    avatar36: new DbInfo('プロフィール画像36', 36),
    custom: new DbInfo('カスタム', 99),
  },
  stamp: {
    stamp1: new DbInfo('感想スタンプ1', 1),
    stamp2: new DbInfo('感想スタンプ2', 2),
    stamp3: new DbInfo('感想スタンプ3', 3),
    stamp4: new DbInfo('感想スタンプ4', 4),
    stamp5: new DbInfo('感想スタンプ5', 5),
    stamp6: new DbInfo('感想スタンプ6', 6),
    stamp7: new DbInfo('感想スタンプ7', 7),
    stamp8: new DbInfo('感想スタンプ8', 8),
    stamp9: new DbInfo('感想スタンプ9', 9),
    stamp10: new DbInfo('感想スタンプ10', 10),
    stamp11: new DbInfo('感想スタンプ11', 11),
    stamp12: new DbInfo('感想スタンプ12', 12),
    stamp13: new DbInfo('感想スタンプ13', 13),
    stamp14: new DbInfo('感想スタンプ14', 14),
    stamp15: new DbInfo('感想スタンプ15', 15),
    stamp16: new DbInfo('感想スタンプ16', 16),
    stamp17: new DbInfo('感想スタンプ17', 17),
    stamp18: new DbInfo('感想スタンプ18', 18),
    stamp19: new DbInfo('感想スタンプ19', 19),
    stamp20: new DbInfo('感想スタンプ20', 20),
    stamp21: new DbInfo('感想スタンプ21', 21),
    stamp22: new DbInfo('感想スタンプ22', 22),
    stamp23: new DbInfo('感想スタンプ23', 23),
    stamp24: new DbInfo('感想スタンプ24', 24),
    stamp25: new DbInfo('感想スタンプ25', 25),
    stamp26: new DbInfo('感想スタンプ26', 26),
    stamp27: new DbInfo('感想スタンプ27', 27),
    stamp28: new DbInfo('感想スタンプ28', 28),
    stamp29: new DbInfo('感想スタンプ29', 29),
    stamp30: new DbInfo('感想スタンプ30', 30),
  },
  achievement: {
    firstLogin: new DbInfo('はじめてのログイン', 1),
    changedDisplayName: new DbInfo('はじめてのニックネーム登録', 2),
    changedAvatar: new DbInfo('はじめてのアイコン設定', 3),
    readBook: new DbInfo('はじめての読書', 4),
    firstReview: new DbInfo('はじめての感想', 5),
    rankingCreated: new DbInfo('はじめてのランキング作成', 6),
    gacha: new DbInfo('はじめてのガチャ', 7),
    customize: new DbInfo('はじめてのカスタマイズ', 8),
  },
  //TODO: DBでマスタ管理する方針なので、ここいらないかも
  dust: {
    dust1: new DbInfo('ほこり1', 1),
    dust2: new DbInfo('ほこり2', 2),
    dust3: new DbInfo('ほこり3', 3),
    dust4: new DbInfo('ほこり4', 4),
    dust5: new DbInfo('ほこり5', 5),
  },
  decoration: {
    decoration1: new DbInfo('カップケーキ', 1),
    decoration2: new DbInfo('トイレットペーパー', 2),
    decoration3: new DbInfo('ぬいぐるみ', 3),
    decoration4: new DbInfo('マグカップ', 4),
    decoration5: new DbInfo('リップ', 5),
    decoration6: new DbInfo('ロボット', 6),
    decoration7: new DbInfo('恐竜', 7),
    decoration8: new DbInfo('車の模型', 8),
    decoration9: new DbInfo('A', 9),
    decoration10: new DbInfo('B', 10),
    decoration11: new DbInfo('C', 11),
    decoration12: new DbInfo('D', 12),
    decoration13: new DbInfo('E', 13),
    decoration14: new DbInfo('F', 14),
    decoration15: new DbInfo('G', 15),
    decoration16: new DbInfo('H', 16),
    decoration17: new DbInfo('I', 17),
    decoration18: new DbInfo('J', 18),
    decoration19: new DbInfo('K', 19),
    decoration20: new DbInfo('L', 20),
    decoration21: new DbInfo('M', 21),
    decoration22: new DbInfo('N', 22),
    decoration23: new DbInfo('O', 23),
    decoration24: new DbInfo('P', 24),
    decoration25: new DbInfo('Q', 25),
    decoration26: new DbInfo('R', 26),
    decoration27: new DbInfo('S', 27),
    decoration28: new DbInfo('T', 28),
    decoration29: new DbInfo('U', 29),
    decoration30: new DbInfo('V', 30),
    decoration31: new DbInfo('W', 31),
    decoration32: new DbInfo('X', 32),
    decoration33: new DbInfo('Y', 33),
    decoration34: new DbInfo('Z', 34),
  },
  itemType: {
    accessory: new DbInfo('小物', 1),
    design: new DbInfo('本棚模様', 2),
  },
  pointType: {
    earned: new DbInfo('獲得', 1),
    consumed: new DbInfo('利用', 2),
  },
  action: {
    login: new DbInfo('ログイン', 1),
    logout: new DbInfo('ログアウト', 2),
    gacha: new DbInfo('ガチャを回した', 3),
    search: new DbInfo('本を検索した', 4),
    finishedReading: new DbInfo('本を読み終わった', 5),
    commentCreated: new DbInfo('コメントを作成した', 6),
    commentPublished: new DbInfo('コメントを公開した', 7),
    rankingCreated: new DbInfo('ランキングを作成した', 8),
    rankingPublished: new DbInfo('ランキングを公開した', 9),
    itemPurchased: new DbInfo('アイテムを購入した', 10),
    bookshelfReorganized: new DbInfo('本棚を並べ替えた', 11),
  },
  format: {
    epub: new DbInfo('eEPUB3(PUB)', 0),
    pdf: new DbInfo('PDF', 1),
  },
  pageBindingType: {
    right: new DbInfo('右綴じ', 0),
    left: new DbInfo('左綴じ', 1),
  },
  contentsStyle: {
    reflow: new DbInfo('リフロー', 0),
    fixed: new DbInfo('フィックス', 1),
  },
  authorizedArea: {
    international: new DbInfo('国内・国外可', 0),
    restricted: new DbInfo('国外不可', 1),
  },
  contractSearchType: {
    application: new DbInfo('申込み', 0),
    contract: new DbInfo('契約', 1),
  },
  categoryList: {
    pictureBook: new FirstLayer('絵本', '001', [
      new SecondaryLayer('日本の絵本', '01'),
      new SecondaryLayer('世界の絵本', '02'),
      new SecondaryLayer('写真絵本', '03'),
      new SecondaryLayer('昔話', '04'),
      new SecondaryLayer('その他', '05'),
    ]),
    readingMatter: new FirstLayer('読み物', '002', [
      new SecondaryLayer('日本の読み物', '01'),
      new SecondaryLayer('世界の読み物', '02'),
      new SecondaryLayer('YA', '03'),
      new SecondaryLayer('ノンフィクション', '04'),
      new SecondaryLayer('その他', '05'),
    ]),
    learning: new FirstLayer('学習', '003', [
      new SecondaryLayer('伝記', '01'),
      new SecondaryLayer('仕事', '02'),
      new SecondaryLayer('古典', '03'),
      new SecondaryLayer('戦争・平和', '04'),
      new SecondaryLayer('その他', '05'),
    ]),
    learningComic: new FirstLayer('学習コミック', '004', [
      new SecondaryLayer('歴史', '01'),
      new SecondaryLayer('伝記', '02'),
      new SecondaryLayer('その他', '03'),
    ]),
    sportsPlay: new FirstLayer('スポーツ・あそび', '005', [
      new SecondaryLayer('スポーツ', '01'),
      new SecondaryLayer('なぞなぞ・あそび', '02'),
      new SecondaryLayer('その他', '03'),
    ]),
    booksInTextBooks: new FirstLayer('教科に関連する本', '006', [
      new SecondaryLayer('国語', '01'),
      new SecondaryLayer('総合', '02'),
      new SecondaryLayer('その他', '03'),
    ]),
  },
  functionLimitCode: {
    restrictionsWritingReviews: new DbInfo('感想・ランキング作成機能制限', 1),
    publicRestrictions: new DbInfo('感想・ランキング公開機能制限', 2),
  },
  constraintOption: {
    none: new DbInfo('制約なし', 0),
    library: new DbInfo('公共図書館', 1),
    whitelist: new DbInfo('ホワイトリスト', 2),
  },
})
