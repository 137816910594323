// Vueのprovide/injectを利用するためのキーを定義
export const injectionKeys = {
  isUserId: Symbol(),
  isNextGiga: Symbol(),
  isSharedId: Symbol(),
  isFixedIp: Symbol(),
  isWhitelist: Symbol(),
  forbidRegister: Symbol(),
  forbidPublish: Symbol(),
  $config: Symbol(),
  $store: Symbol(),
  $validation: Symbol(),
  $enum: Symbol(),
  $date: Symbol(),
  $dbEnum: Symbol(),
  $gaEvNameEnum: Symbol(),
  isAdmin: Symbol(),
  isPublisher: Symbol(),
}
