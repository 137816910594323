<template>
  <div class="book" :class="`book--${pattern}`">
    <div v-if="isVisibleNewArea" class="book__status is-new">
      <div>
        <span v-if="newBookFlg === 1">NEW!</span>
      </div>
      <div>
        <span class="text__date">{{ releaseEndDate }}</span>
      </div>
    </div>
    <div class="book__thumb">
      <div class="book__thumb_img">
        <router-link
          :to="{
            name: 'BookDetail',
            params: { bookId: contentsId },
          }"
          class="book__thumb_link"
          @click="emit('click:book', contentsId)"
        >
          <AppImage
            :src="thumbnailPath ?? require('@/assets/image/img_book_none.svg')"
            :noImageSrc="require('@/assets/image/img_book_404.svg')"
            width="200"
            height="200"
            alt=""
          />
        </router-link>
      </div>
      <div v-if="hasAction" class="book__actionArea">
        <button
          class="actionButton animationLink"
          :class="isLoading ? 'is-loading' : ''"
          @click="onClickToggle(contentsId, bookShelfEntryFlg)"
        >
          <AppButton
            tag="span"
            :color="bookShelfEntryFlg === 0 ? 'add' : 'remove'"
            shadow="s"
          >
            <template v-if="bookShelfEntryFlg === 0">
              <div class="actionButton__inner">
                <span class="actionButton__icon">
                  <AppImage
                    :src="require('@/assets/image/icon/icon_book_plus_s.svg')"
                    alt="ほんだなにいれる"
                    width="32"
                    height="32"
                  />
                </span>
                <span class="actionButton__text">いれる</span>
              </div>
            </template>
            <template v-else>
              <div class="actionButton__inner">
                <span class="actionButton__icon">
                  <AppImage
                    :src="require('@/assets/image/icon/icon_book_minus_s.svg')"
                    alt="ほんだなからはずす"
                    width="32"
                    height="32"
                  />
                </span>
                <span class="actionButton__text">はずす</span>
              </div>
            </template>
          </AppButton>
        </button>
      </div>
    </div>
    <div v-if="contentsName" class="book__name">{{ contentsName }}</div>
    <ul v-if="gradeCodes" class="book__grades">
      <li v-for="(grade, i) in gradeCodes" :key="i" class="book__grade">
        <span>{{ gradeText(grade) }}</span>
      </li>
    </ul>
    <div v-if="stampId || contentsStyle === 0" class="book__foot">
      <div v-if="stampId" class="book__stamp">
        <span class="book__stamp_icon">
          <AppImage
            :src="require(`@/assets/image/icon/stamp/${stampId}.svg`)"
            :alt="getStampDisplayName(stampId)"
            width="20"
            height="20"
          />
        </span>
        <span class="book__stamp_count">{{ stampIdCount }}</span>
      </div>
      <div v-if="contentsStyle === 0" class="book__readSpeaker">
        <AppImage
          :src="require('@/assets/image/icon/icon_speaker.svg')"
          alt="読み上げ"
          lazyLoad
          width="20"
          height="20"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * Book.
 *
 * 書影.
 */

import { defineProps, withDefaults, defineEmits, computed } from 'vue'

import { STAMPS } from '@/constants/stamps'

import AppImage from '@/components/AppImage/AppImage.vue'
import AppButton from '@/components/AppButton/AppButton.vue'

interface Props {
  contentsId: string
  contentsName?: string
  contentsStyle?: number
  gradeCodes?: number[]
  newBookFlg?: number | null
  stampId?: number | null
  stampIdCount?: number | null
  thumbnailPath: string
  bookShelfEntryFlg?: number
  hasAction?: boolean
  isLoading?: boolean
  isVisibleNewArea?: boolean
  pattern?: string | 'small' | 'default'
  releaseEndDate?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  contentsName: '',
  contentsStyle: undefined,
  gradeCodes: undefined,
  newBookFlg: 0,
  bookShelfEntryFlg: undefined,
  stampId: undefined,
  stampIdCount: undefined,
  hasAction: true,
  isLoading: false,
  isVisibleNewArea: true,
  pattern: 'default',
  releaseEndDate: null,
})

const emit = defineEmits(['add', 'remove', 'click:book'])

const gradeText = computed(() => {
  return (grade: number) => {
    switch (grade) {
      case 1:
        return '1・2ねんせい'
      case 2:
        return '3・4年生'
      case 3:
        return '5・6年生'
      case 4:
        return '中・高校生'
      case 5:
        return '小学生'
      case 6:
        return '低・中学年'
      case 7:
        return '中・高学年'
      case 8:
        return '高学年以上'
      default:
        return ''
    }
  }
})

// 本だな追加・削除
const onClickToggle = (
  contentsId: Props['contentsId'],
  bookShelfEntryFlg: Props['bookShelfEntryFlg']
) => {
  if (!bookShelfEntryFlg) {
    emit('add', contentsId)
  } else {
    emit('remove', contentsId)
  }
}

// スタンプ
const getStampDisplayName = (id: number) => {
  return STAMPS.filter((item) => id === item.id)[0].displayName
}
</script>

<style lang="scss" scoped>
@import 'Book';
</style>
